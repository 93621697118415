import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import Player from './player'
import SpotifyLogo from '../components/spotifyLogo'

const Home = () => {
    const [hasCode, setHasCode] = useState(false)
    const [showBlob, setShowBlob] = useState(false)
    const [loading, setLoading] = useState(false)

    const REFRESH_EARLY_SECONDS = 600

    useEffect(() => {
        const refreshAccessToken = () => {
            // Fetch token expiration time from localStorage
            const expiresIn = parseFloat(localStorage.getItem('expires_in'))
            const receivedAt = parseFloat(localStorage.getItem('received_at'))
            const refreshToken = localStorage.getItem('refresh_token')

            // Calculate the time remaining until token expiration in seconds
            const currentTime = DateTime.now().toSeconds()
            const timeRemaining = receivedAt + expiresIn - currentTime - REFRESH_EARLY_SECONDS

            if (timeRemaining <= 0) {
                // Token has expired, refresh it
                setLoading(true)

                fetch(`${process.env.REACT_APP_SERVER_LOCATION}/refresh?refresh_token=${refreshToken}`)
                    .then((response) => {
                        if (response.ok) return response.json()
                        throw new Error('Failed to refresh the access token.')
                    })
                    .then((data) => {
                        console.log(data)
                        localStorage.setItem('access_token', data.access_token)
                        localStorage.setItem('expires_in', data.expires_in)
                        localStorage.setItem('received_at', data.received_at)
                        setLoading(false)

                        // Schedule a new refresh after the new token expires
                        const newExpiresIn = parseFloat(data.expires_in)
                        setTimeout(refreshAccessToken, (newExpiresIn - REFRESH_EARLY_SECONDS) * 1000)
                    })
                    .catch((err) => {
                        // Handle token refresh error
                        console.error(err)
                        setHasCode(false)
                        setShowBlob(false)
                        setLoading(false)
                    })
            } else {
                setTimeout(refreshAccessToken, timeRemaining * 1000)
            }
        }

        if (localStorage.getItem('access_token') === null || localStorage.getItem('refresh_token') === null || localStorage.getItem('expires_in') === null || localStorage.getItem('received_at') === null) {
            if (localStorage.getItem('code') === null) {
                // prompt user to login/accept-permissions (click -> spotify)
                setHasCode(false)
                setShowBlob(false)
                setLoading(false)
            } else {
                setHasCode(true)
                setLoading(true)

                // get tokens (fetch -> server/token w/ code)
                fetch(`${process.env.REACT_APP_SERVER_LOCATION}/token?code=${localStorage.getItem('code')}`).then((response) => {
                    if (response.ok)
                        return response.json()
                    throw new Error('Could not use authentication code to get an access token.')
                }).then((data) => {
                    console.log(data)
                    localStorage.setItem('access_token', data.access_token)
                    localStorage.setItem('refresh_token', data.refresh_token)
                    localStorage.setItem('expires_in', data.expires_in)
                    localStorage.setItem('received_at', data.received_at)
                    setLoading(false)
                    setShowBlob(true)

                    setTimeout(refreshAccessToken, (data.expires_in - REFRESH_EARLY_SECONDS) * 1000)
                }).catch((err) => {
                    // fail: show error msg, prompt user to login/accept-permissions again (click -> spotify)
                    console.error(err)
                    setHasCode(false)
                    setLoading(false)
                    setShowBlob(false)
                })
            }
        }

        if (localStorage.getItem('access_token') !== null && localStorage.getItem('refresh_token') !== null && localStorage.getItem('expires_in') !== null && localStorage.getItem('received_at') !== null) {
            setHasCode(true)
            setShowBlob(true)

            // set timeout for refresh -> expire: (fetch -> server/refresh w/ refresh_token)
            refreshAccessToken()
            // fail: get tokens (fetch -> server/token w/ code)
            // fail: show error msg, prompt user to login/accept-permissions again (click -> spotify)
        }
    }, [])

    return (
        <div>
            {!hasCode &&
                <div className='loginContainer'>
                    <div className='login'>
                        <h1>The Blob</h1>
                        <h2>for <SpotifyLogo /></h2>
                        <a href={`${process.env.REACT_APP_SERVER_LOCATION}/login`} className='connectButton'>Connect Account</a>
                        <p className='fine'>Requires a Premium plan</p>
                    </div>
                </div>
            }

            {/* {loading && <div>Loading...</div>} */}

            {loading && <div></div>}

            <Player showBlob={showBlob} />
        </div>
    )
}

export default Home
