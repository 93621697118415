import { useDetectAdBlock } from "adblock-detect-react";
import classNames from "classnames";

const AdBlockDetector = () => {
    const adBlockDetected = useDetectAdBlock();

    const adBlockAlertClasses = classNames('adBlockAlert', {
        show: adBlockDetected,
    });

    return (
        <div className={adBlockAlertClasses}>
            <div>
                <p>Hey there, please turn off your ad blocker so this app works smoothly.</p>
                <p>Ad blockers can sometimes cause issues with connecting to Spotify.</p>
            </div>
            <div>
                <input type="checkbox" name="rememberAdBlockDecision" />
                <label htmlFor="rememberAdBlockDecision">Never show this warning again</label>
            </div>
            <button>Dismiss</button>
        </div>
    );
}

export default AdBlockDetector;
