import { useEffect, useLayoutEffect, useState } from 'react'
import Controls from './controls'
import SongInfo from '../components/songInfo'
import Visualizer from '../components/visualizer'
import FooterLogo from '../components/footerLogo'

const Player = () => {
    const track = {
        id: "",
        name: "",
        album: {
            images: [
                { url: "" }
            ]
        },
        artists: [
            { name: "" }
        ]
    }

    const [player, setPlayer] = useState(undefined)
    const [deviceID, setDeviceID] = useState(undefined)
    const [is_active, setActive] = useState(false)
    const [is_paused, setPaused] = useState(true)
    const [current_track, setTrack] = useState(track)
    const [liked, setLiked] = useState(false);
    const [segments, setSegments] = useState(null)
    const [beats, setBeats] = useState(null)
    const [meta, setMeta] = useState(null)
    const [timbre, setTimbre] = useState(null)
    const [shuffle, setShuffle] = useState(false)
    const [repeat, setRepeat] = useState('')
    const [explicit, setExplicit] = useState(false)
    const [position, setPosition] = useState(0);
    const [position0, setPosition0] = useState(0);
    const [duration, setDuration] = useState(0);
    const [updateTime, setUpdateTime] = useState(null);

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://sdk.scdn.co/spotify-player.js'
        script.async = true

        document.body.appendChild(script)

        window.onSpotifyWebPlaybackSDKReady = () => {
            const player = new window.Spotify.Player({
                name: 'The Blob for Spotify',
                getOAuthToken: cb => { cb(localStorage.getItem('access_token')) },
                volume: 0.5
            })

            setPlayer(player)

            player.addListener('ready', ({ device_id }) => {
                console.log('Ready with Device ID', device_id)
                setDeviceID(device_id)

                fetch('https://api.spotify.com/v1/me/player', {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'device_ids': [
                            device_id
                        ]
                    })
                })
                    .then(response => {
                        console.log(response)

                        player.resume().then(() => {
                            console.log('Resumed!')
                        }).catch((e) => {
                            console.error(e)
                        })
                    })
                    .catch(error => {
                        console.error(error)
                    })
            })

            player.addListener('not_ready', ({ device_id }) => {
                console.log('Device ID has gone offline', device_id)
            })

            player.addListener('player_state_changed', (state => {
                if (!state)
                    return

                setPosition(state.position)
                setDuration(state.duration)
                setUpdateTime(performance.now())

                fetch(`https://api.spotify.com/v1/me/player`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                        'Content-Type': 'application/json'
                    },
                })
                    .then(response => {
                        return response.text();
                    })
                    .then(data => {
                        if (data) {
                            data = JSON.parse(data)
                            const id = data.item.id

                            fetch(`https://api.spotify.com/v1/me/tracks/contains?ids=${id}`, {
                                method: 'GET',
                                headers: {
                                    'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                                    'Content-Type': 'application/json'
                                },
                            })
                                .then(response => {
                                    return response.json();
                                })
                                .then(data => {
                                    setLiked(data[0]);
                                })
                                .catch(error => {
                                    console.error(error);
                                });

                            fetch(`https://api.spotify.com/v1/audio-analysis/${id}`, {
                                method: 'GET',
                                headers: {
                                    'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                                    'Content-Type': 'application/json'
                                },
                            })
                                .then(response => {
                                    return response.json();
                                })
                                .then(data => {
                                    setSegments(data.segments)
                                    if (data.segments) {
                                        const all_max = data.segments.map(obj => obj.loudness_max)
                                        const all_timbre = data.segments.map(obj => obj.timbre.map(value => (1 / 12) * value).reduce((acc, val) => acc + val, 0))
                                        const all_bright = data.segments.map(obj => obj.timbre[3])
                                        const all_attack = data.segments.map(obj => obj.timbre[3])
                                        setTimbre(all_attack)

                                        setBeats(data.beats)

                                        let test = []

                                        for (let i = 0; i < 12; i++) {
                                            test[i] = {
                                                data: data.segments.map(obj => obj.timbre[i]),
                                                min: Math.min(...data.segments.map(obj => obj.timbre[i])),
                                                max: Math.max(...data.segments.map(obj => obj.timbre[i]))
                                            }
                                        }

                                        setMeta({ min: Math.min(...all_max), max: Math.max(...all_max), min_a: Math.min(...all_attack), max_a: Math.max(...all_attack), min_b: Math.min(...all_bright), max_b: Math.max(...all_bright), min_t: Math.min(...all_timbre), max_t: Math.max(...all_timbre), test: test })
                                    }
                                })
                                .catch(error => {
                                    console.error(error);
                                });

                            setRepeat(data.repeat_state)
                            setShuffle(data.shuffle_state)
                            setExplicit(data.item.explicit)
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });

                (state.context.uri === null) ? setActive(false) : setActive(true)
                setTrack(state.track_window.current_track)
                setPaused(state.paused)
            }))

            player.connect()
        }
    }, [])

    useLayoutEffect(() => {
        const getStatePosition = () => {
            if (is_paused) {
                return position ? position : 0;
            }
            const position2 = position + (performance.now() - updateTime);
            setPosition0(position2 > duration ? duration : position2)
        }
        const progressInterval = setInterval(getStatePosition, 10)
        return () => {
            clearInterval(progressInterval);
        };
    })

    return (
        <div>
            <div className='mediaBarContainer'>
                <div className='mediaBar'>
                    <SongInfo active={is_active} current_track={current_track} explicit={explicit} />
                    <Controls player={player} is_paused={is_paused} is_active={is_active} deviceID={deviceID} />
                </div>
            </div>
            <Visualizer paused={is_paused || !is_active} beats={beats} segments={segments} position={position0} progress={0} duration={1} meta={meta} timbre={timbre} />
            <FooterLogo />

            {liked && shuffle && repeat && <div></div>}
        </div>
    )
}

export default Player
