import SpotifyLogo from "./spotifyLogo"

const FooterLogo = () => {
    return (
        <div className="logo">
            <div className="logo-top">The Blob</div>
            <div className="logo-bottom">for <SpotifyLogo /></div>
        </div>
    )
}

export default FooterLogo
