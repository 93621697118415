import { OrbitControls, /* Cloud, */ Sparkles } from "@react-three/drei";
import { Canvas, useFrame, /* useThree */ } from "@react-three/fiber";
import { useMemo, useRef, /* useEffect, */ Suspense, useState } from "react";
import { MathUtils, UniformsUtils, UniformsLib } from "three";
/* import * as THREE from "three";
import { Water } from 'three-stdlib' */

import vertexShader from "../glsl/vertexShader";
import fragmentShader from "../glsl/fragmentShader";


function Thing(props) {
  const ref = useRef()
  const hover = useRef(false)

  const [beatIntensity, setBeatIntensity] = useState(0.2)

  const uniforms = useMemo(
    () =>
      UniformsUtils.merge([
        UniformsLib.lights,
        {
          lightIntensity: { type: 'f', value: 1.0 },
          textureSampler: { type: 't', value: null },
          u_intensity: {
            value: 0.1
          },
          u_time: {
            value: 0.0
          },
          pitch1: {
            value: 0 //props.pitches1 ? props.pitches[0] + props.pitches[1] : 0
          },
          pitch2: {
            value: 0 //props.pitches ? props.pitches[2] + props.pitches[3] : 0
          },
          pitch3: {
            value: 0 //props.pitches ? props.pitches[4] + props.pitches[5] : 0
          },
          pitch4: {
            value: 0 //props.pitches ? props.pitches[6] + props.pitches[7] : 0
          },
          pitch5: {
            value: 0 //props.pitches ? props.pitches[8] + props.pitches[9] : 0
          },
          pitch6: {
            value: 0 //props.pitches ? props.pitches[10] + props.pitches[11] : 0
          },
        }]),
    []
  )

  useFrame((state) => {
    const { clock, camera } = state

    ref.current.material.uniforms.u_time.value = MathUtils.lerp(
      ref.current.material.uniforms.u_time.value,
      0.1 * clock.getElapsedTime(),
      0.8
    )
    /* ref.current.material.uniforms.u_intensity.value = MathUtils.lerp(
      ref.current.material.uniforms.u_intensity.value,
      props.intensity ? Math.max(props.intensity * 2 - 0.6, 0) : 0,
      0.15
    ) */

    ref.current.material.uniforms.pitch1.value = MathUtils.lerp(
      ref.current.material.uniforms.pitch1.value,
      !isNaN(props.pitches[0]) && !isNaN(props.pitches[1]) && props.volume ? props.volume * (props.pitches[0] + props.pitches[1]) / 200 : 0,
      0.1
    )

    ref.current.material.uniforms.pitch2.value = MathUtils.lerp(
      ref.current.material.uniforms.pitch2.value,
      !isNaN(props.pitches[2]) && !isNaN(props.pitches[3]) && props.volume ? props.volume * (props.pitches[3] + props.pitches[3]) / 200 : 0,
      0.1
    )

    ref.current.material.uniforms.pitch3.value = MathUtils.lerp(
      ref.current.material.uniforms.pitch3.value,
      !isNaN(props.pitches[4]) && !isNaN(props.pitches[5]) && props.volume ? props.volume * (props.pitches[4] + props.pitches[5]) / 200 : 0,
      0.1
    )

    ref.current.material.uniforms.pitch4.value = MathUtils.lerp(
      ref.current.material.uniforms.pitch4.value,
      !isNaN(props.pitches[6]) && !isNaN(props.pitches[7]) && props.volume ? props.volume * (props.pitches[6] + props.pitches[7]) / 200 : 0,
      0.1
    )

    ref.current.material.uniforms.pitch5.value = MathUtils.lerp(
      ref.current.material.uniforms.pitch5.value,
      !isNaN(props.pitches[8]) && !isNaN(props.pitches[9]) && props.volume ? props.volume * (props.pitches[8] + props.pitches[9]) / 200 : 0,
      0.1
    )

    ref.current.material.uniforms.pitch6.value = MathUtils.lerp(
      ref.current.material.uniforms.pitch6.value,
      !isNaN(props.pitches[10]) && !isNaN(props.pitches[11]) && props.volume ? props.volume * (props.pitches[10] + props.pitches[11]) / 200 : 0,
      0.1
    )

    /* setBeatIntensity(MathUtils.lerp(
      beatIntensity,
      !isNaN(props.beatIntensity) && props.volume && props.intensity ? (props.intensity < 0.7 ? 0.1 : props.volume / 100 * props.beatIntensity) : 0,
      0.4
    )) */

    setBeatIntensity(MathUtils.lerp(
      beatIntensity,
      !isNaN(props.beatIntensity) && props.volume ? Math.max(props.volume / 100 * props.beatIntensity, 0.2) : 0.2,
      props.intensity ? 0.5 : 0.001
    ))


    /* ref.current.rotation.y = ref.current.rotation.z += 0.003 * (props.intensity ? props.intensity * 0.75 : 1) */

    camera.zoom = MathUtils.lerp(
      camera.zoom,
      props.intensity ? Math.max((props.volume / 100 * 1.5), 1) : 1,
      props.intensity ? 0.05 : 0.001
    )
  })

  return (
    <>
      {/* <hemisphereLight skyColor="#fff" groundColor="#fff" /> */}
      {/* <SpotLight penumbra={1} distance={80} angle={-50} attenuation={0.5} anglePower={3} intensity={10} color="#fff" position={[0, 0, 0]} /> */}
      <mesh>
        {/* <sphereGeometry args={[3, 32, 16]} />
        <meshStandardMaterial side={THREE.DoubleSide} color="#000" attach="material" /> */}
        {/* <sphereGeometry args={[6, 32, 16]} />
        <meshStandardMaterial color={'#191414'} side={THREE.BackSide} /> */}
      </mesh>
      <mesh ref={ref} onPointerOver={() => (hover.current = true)} onPointerOut={() => (hover.current = false)}>
        <icosahedronGeometry args={[1, 15]} />
        <shaderMaterial wireframe={true} fragmentShader={fragmentShader} vertexShader={vertexShader} uniforms={uniforms} lights={true} transparent={true} />
        <Sparkles color="#fff" count={150} scale={25} size={4} speed={0.5} opacity={(beatIntensity) ? beatIntensity : 1} />
      </mesh>
    </>
  )
}

function Orbit(props) {
  const ref2 = useRef()

  useFrame(() => {
    let speed = 1.5;

    if (props.intensity && props.intensity > 0.6) {
      if (props.intensity < 0.7)
        speed = 2
      else if (props.intensity < 0.8)
        speed = 4
      else if (props.intensity < 0.83)
        speed = 10
      else if (props.intensity < 0.88)
        speed = 15
      else
        speed = 45
    }

    ref2.current.autoRotateSpeed = MathUtils.lerp(
      ref2.current.autoRotateSpeed,
      speed,
      0.1
    )
  })

  /* const { camera, gl, size } = useThree()

  // Remove the event listener when the component unmounts
  useEffect(() => {
    const onWindowResize = () => {
      const { clientWidth, clientHeight } = gl.domElement;
      console.log(gl.domElement)
      camera.aspect = clientWidth / clientHeight;
      camera.updateProjectionMatrix();
      gl.setSize(clientWidth, clientHeight);
    };

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [camera, gl]); */

  return (
    <OrbitControls ref={ref2} autoRotate={true} autoRotateSpeed={1.5} enableZoom={false} enableRotate={false} enablePan={false} />
  )
}

/* function Bloom({ children }) {
  const { gl, camera, size } = useThree()
  const [scene, setScene] = useState()
  const composer = useRef()
  useEffect(() => void scene && composer.current.setSize(size.width, size.height), [size, scene])
  useFrame(() => scene && composer.current.render(), 1)
  return (
    <>
      <scene ref={setScene}>{children}</scene>
      <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        <unrealBloomPass attachArray="passes" args={[undefined, 1.5, 1, 0]} />
      </effectComposer>
    </>
  )
} */

export default function BlobTest(props) {
  /* const [viewportSize, setViewportSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Update viewport size on window resize
    const handleResize = () => {
      setViewportSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); */

  return (
    <div style={{ width: "100vw", height: "calc(calc(var(--vh, 1vh) * 100) + 50px)", position: "fixed", top: 0, left: 0, zIndex: -1 }}>
      <Canvas camera={{ position: [2.5, -3, 2.5] }}>
        <ambientLight intensity={0.8} />
        <pointLight intensity={2} position={[0, 0, -1000]} />
        <Suspense fallback={null}>
          {/*  <spotLight position={[1, 1, 1]} angle={0.15} penumbra={1} /> */}
          {/* <Cloud position={[10, 0, 0]} speed={0.2} opacity={1} color="#fff" /> */}
          {/* <Cloud position={[0, -20, 0]} speed={0.2} opacity={1} color="#fff" />
          <Cloud position={[0, 0, -20]} speed={0.2} opacity={1} color="#fff" /> */}
        </Suspense>
        <Thing intensity={props.intensity} volume={props.volume} pitches={props.pitches} beatIntensity={props.beatIntensity} />
        <Orbit intensity={props.intensity} />
      </Canvas>
    </div >
  )
}
