import { BsFillExplicitFill } from "react-icons/bs";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function SongInfo(props) {
    const current_track = props.current_track
    const explicit = props.explicit

    const show = (current_track !== null && current_track.name !== "")

    const fadeIn = (e) => {
        e.target.classList.add('loaded')
    }

    return (
        <div>
            {show &&
                <div className="songInfo">
                    <div className="coverContainer">
                        <img src={current_track.album.images[0].url} className="cover loadingImage" alt="" onLoad={fadeIn} key={current_track.album.images[0].url} />
                    </div>

                    <div className="words">
                        <div className="primary">
                            <p className="songName">{current_track.name}</p>
                            {explicit && <BsFillExplicitFill className="explicit" />}
                        </div>

                        <div className="secondary">
                            <p className="artistName">{current_track.artists.map(artist => artist.name).join(", ")}</p>
                            <span className="separator">&#8212;</span>
                            <p className="albumName">{current_track.album.name}</p>
                        </div>
                    </div>

                    <img src={current_track.album.images[0].url} className="coverBlurred loadingImage" alt="" onLoad={fadeIn} key={current_track.album.images[0].url} />
                </div>
            }

            {!show &&
                <div className="songInfo">
                    <SkeletonTheme baseColor="#202020" highlightColor="rgba(255,255,255,0.1)" /* enableAnimation={props.active} */>
                        <div className="coverContainer">
                            <div className="cover">
                                <Skeleton height={"100%"} style={{ lineHeight: 'normal' }} />
                            </div>
                        </div>

                        <div className="words">
                            <div className="primary">
                                <p className="songName">
                                    <Skeleton width={'10rem'} />
                                </p>
                            </div>

                            <div className="secondary" style={{ opacity: 1 }}>
                                <p className="artistName">
                                    <Skeleton width={'5rem'} />
                                </p>
                            </div>
                        </div>
                    </SkeletonTheme>
                </div>
            }
        </div>
    )
}