const SpotifyLogo = () => {
    return (
        <svg version="1.1" id="svg2" viewBox="0 0 755.90668 226.72" sodipodidocname="Spotify_Logo_RGB_White.eps" xmlnsinkscape="http://www.inkscape.org/namespaces/inkscape" xmlnssodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg" xmlnssvg="http://www.w3.org/2000/svg">
            <defs id="defs6" />
            <sodipodiNamedview id="namedview4" pagecolor="#ffffff" bordercolor="#000000" borderopacity="0.25" inkscapeshowpageshadow="2" inkscapepageopacity="0.0" inkscapepagecheckerboard="0" inkscapeseskcolor="#d1d1d1" />
            <g id="g8" inkscapegroupmode="layer" inkscapelabel="ink_ext_XXXXXX" transform="matrix(1.3333333,0,0,-1.3333333,0,226.72)">
                <g id="g10" transform="scale(0.1)">
                    <path d="m 1375.32,945.234 c -269.94,160.306 -715.199,175.046 -972.89,96.836 -41.383,-12.55 -85.141,10.81 -97.68,52.19 -12.539,41.4 10.797,85.13 52.207,97.71 295.813,89.8 787.553,72.45 1098.313,-112.02 37.23,-22.09 49.44,-70.16 27.37,-107.329 -22.08,-37.215 -70.19,-49.484 -107.32,-27.387 z m -8.84,-237.441 c -18.93,-30.734 -59.11,-40.363 -89.8,-21.504 -225.05,138.336 -568.223,178.41 -834.473,97.594 -34.527,-10.434 -70.996,9.031 -81.48,43.496 -10.407,34.527 9.07,70.93 43.535,81.43 304.156,92.291 682.258,47.589 940.738,-111.258 30.69,-18.899 40.35,-59.102 21.48,-89.758 z M 1264.01,479.762 c -15.04,-24.672 -47.18,-32.41 -71.76,-17.371 -196.656,120.187 -444.176,147.339 -735.676,80.75 -28.094,-6.442 -56.086,11.168 -62.496,39.25 -6.433,28.089 11.102,56.078 39.258,62.488 318.996,72.926 592.634,41.539 813.364,-93.34 24.61,-15.027 32.36,-47.18 17.31,-71.777 z M 879.957,1687.63 c -462.492,0 -837.4336,-374.92 -837.4336,-837.415 0,-462.535 374.9416,-837.4455 837.4336,-837.4455 462.513,0 837.433,374.9105 837.433,837.4455 0,462.495 -374.92,837.415 -837.433,837.415" style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzer", stroke: "none" }} id="path12" />
                    <path d="m 2320.89,914.539 c -144.59,34.481 -170.34,58.684 -170.34,109.521 0,48.05 45.24,80.37 112.5,80.37 65.2,0 129.84,-24.55 197.63,-75.09 2.05,-1.53 4.62,-2.14 7.15,-1.74 2.53,0.38 4.76,1.76 6.25,3.86 l 70.6,99.52 c 2.9,4.09 2.11,9.75 -1.8,12.88 -80.67,64.73 -171.51,96.2 -277.69,96.2 -156.12,0 -265.17,-93.68 -265.17,-227.74 0,-143.754 94.07,-194.648 256.63,-233.937 138.36,-31.871 161.71,-58.567 161.71,-106.305 0,-52.887 -47.22,-85.769 -123.21,-85.769 -84.39,0 -153.24,28.429 -230.25,95.121 -1.91,1.64 -4.53,2.398 -6.95,2.261 -2.54,-0.211 -4.87,-1.39 -6.49,-3.339 l -79.16,-94.211 c -3.32,-3.911 -2.9,-9.75 0.93,-13.129 89.61,-79.992 199.8,-122.25 318.72,-122.25 168.23,0 276.94,91.929 276.94,234.199 0,120.238 -71.84,186.734 -248,229.578" style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzer", stroke: "none" }} id="path14" />
                    <path d="m 3071.62,753.391 c 0,-101.532 -62.54,-172.379 -152.09,-172.379 -88.53,0 -155.31,74.066 -155.31,172.379 0,98.297 66.78,172.375 155.31,172.375 88.11,0 152.09,-72.477 152.09,-172.375 z M 2949.5,1057.14 c -72.92,0 -132.73,-28.72 -182.05,-87.57 v 66.24 c 0,5.23 -4.24,9.49 -9.47,9.49 h -129.46 c -5.23,0 -9.46,-4.26 -9.46,-9.49 V 299.789 c 0,-5.23 4.23,-9.488 9.46,-9.488 h 129.46 c 5.23,0 9.47,4.258 9.47,9.488 v 232.32 c 49.33,-55.359 109.15,-82.398 182.05,-82.398 135.49,0 272.65,104.301 272.65,303.68 0,199.422 -137.16,303.749 -272.65,303.749" style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzer", stroke: "none" }} id="path16" />
                    <path d="m 3573.73,579.949 c -92.81,0 -162.78,74.571 -162.78,173.442 0,99.285 67.55,171.336 160.64,171.336 93.41,0 163.85,-74.567 163.85,-173.504 0,-99.274 -68.01,-171.274 -161.71,-171.274 z m 0,477.191 c -174.49,0 -311.19,-134.363 -311.19,-305.917 0,-169.692 135.76,-302.645 309.05,-302.645 175.11,0 312.23,133.91 312.23,304.813 0,170.328 -136.18,303.749 -310.09,303.749" style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzer", stroke: "none" }} id="path18" />
                    <path d="m 4256.44,1045.3 h -142.47 v 145.66 c 0,5.23 -4.22,9.48 -9.45,9.48 h -129.45 c -5.24,0 -9.5,-4.25 -9.5,-9.48 V 1045.3 h -62.25 c -5.22,0 -9.43,-4.26 -9.43,-9.49 V 924.543 c 0,-5.227 4.21,-9.484 9.43,-9.484 h 62.25 V 627.141 c 0,-116.352 57.91,-175.34 172.12,-175.34 46.44,0 84.97,9.59 121.28,30.179 2.95,1.649 4.79,4.829 4.79,8.211 v 105.957 c 0,3.274 -1.71,6.352 -4.5,8.071 -2.82,1.769 -6.33,1.859 -9.22,0.429 -24.94,-12.546 -49.05,-18.339 -76,-18.339 -41.53,0 -60.07,18.851 -60.07,61.121 v 267.629 h 142.47 c 5.23,0 9.44,4.257 9.44,9.484 v 111.267 c 0,5.23 -4.21,9.49 -9.44,9.49" style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzer", stroke: "none" }} id="path20" />
                    <path d="m 4752.81,1044.73 v 17.89 c 0,52.63 20.18,76.1 65.44,76.1 26.99,0 48.67,-5.36 72.95,-13.46 2.99,-0.94 6.11,-0.47 8.54,1.32 2.5,1.79 3.91,4.66 3.91,7.7 v 109.1 c 0,4.17 -2.68,7.86 -6.69,9.08 -25.65,7.63 -58.47,15.47 -107.61,15.47 -119.59,0 -182.79,-67.34 -182.79,-194.67 v -27.4 h -62.2 c -5.22,0 -9.5,-4.25 -9.5,-9.48 V 924.543 c 0,-5.227 4.28,-9.484 9.5,-9.484 h 62.2 V 470.961 c 0,-5.231 4.22,-9.481 9.44,-9.481 h 129.46 c 5.23,0 9.49,4.25 9.49,9.481 v 444.098 h 120.88 L 5061,471.078 c -21.02,-46.648 -41.69,-55.93 -69.91,-55.93 -22.81,0 -46.83,6.813 -71.39,20.25 -2.31,1.274 -5.04,1.481 -7.54,0.711 -2.47,-0.879 -4.55,-2.711 -5.6,-5.121 l -43.88,-96.269 c -2.09,-4.547 -0.31,-9.891 4.07,-12.25 45.81,-24.809 87.17,-35.399 138.27,-35.399 95.6,0 148.44,44.539 195.03,164.34 l 224.61,580.4 c 1.12,2.92 0.79,6.21 -1,8.81 -1.78,2.56 -4.65,4.11 -7.79,4.11 h -134.78 c -4.03,0 -7.65,-2.56 -8.97,-6.35 L 5134.05,644 4982.82,1038.64 c -1.38,3.67 -4.91,6.09 -8.84,6.09 h -221.17" style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzer", stroke: "none" }} id="path22" />
                    <path d="m 4465.05,1045.3 h -129.47 c -5.23,0 -9.48,-4.26 -9.48,-9.49 V 470.961 c 0,-5.231 4.25,-9.481 9.48,-9.481 h 129.47 c 5.22,0 9.48,4.25 9.48,9.481 v 564.849 c 0,5.23 -4.26,9.49 -9.48,9.49" style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzer", stroke: "none" }} id="path24" />
                    <path d="m 4400.97,1302.49 c -51.28,0 -92.91,-41.53 -92.91,-92.81 0,-51.32 41.63,-92.89 92.91,-92.89 51.27,0 92.85,41.57 92.85,92.89 0,51.28 -41.58,92.81 -92.85,92.81" style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzer", stroke: "none" }} id="path26" />
                    <path d="m 5540.49,989.113 h -23.72 v -30.25 h 23.72 c 11.84,0 18.91,5.793 18.91,15.133 0,9.84 -7.07,15.117 -18.91,15.117 z m 15.38,-43.152 25.77,-36.086 h -21.73 l -23.2,33.098 h -19.94 v -33.098 h -18.19 v 95.645 h 42.65 c 22.21,0 36.83,-11.372 36.83,-30.508 0,-15.676 -9.06,-25.258 -22.19,-29.051 z m -20.2,91.379 c -46.67,0 -81.99,-37.09 -81.99,-82.527 0,-45.407 35.07,-82.008 81.51,-82.008 46.66,0 82.01,37.07 82.01,82.527 0,45.408 -35.09,82.008 -81.53,82.008 z m -0.48,-173.649 c -51.24,0 -91.11,41.153 -91.11,91.122 0,49.977 40.39,91.597 91.59,91.597 51.23,0 91.11,-41.15 91.11,-91.078 0,-49.977 -40.36,-91.641 -91.59,-91.641" style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzer", stroke: "none" }} id="path28" />
                </g>
            </g>
        </svg>
    )
}

export default SpotifyLogo
