import { FaBackward, FaForward, FaPause, FaPlay } from "react-icons/fa6"

const Controls = (props) => {
    const ownPlayer = (cb) => {
        fetch('https://api.spotify.com/v1/me/player', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'device_ids': [
                    props.deviceID
                ]
            })
        })
            .then(response => {
                console.log(response)

                if (cb) {
                    cb()
                } else {
                    props.player.resume().then(() => {
                        console.log('Resumed!')
                    }).catch((e) => {
                        console.error(e)
                    })
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    return (
        <div>
            <button onClick={() => {
                if (!props.is_active) {
                    ownPlayer(() => props.player.previousTrack())
                } else {
                    props.player.previousTrack()
                }
            }}>
                <FaBackward />
            </button>

            <button onClick={() => {
                if (!props.is_active) {
                    ownPlayer()
                } else {
                    props.player.togglePlay()
                }
            }}>
                {props.is_paused ? <FaPlay /> : <FaPause />}
            </button>

            <button onClick={() => {
                if (!props.is_active) {
                    ownPlayer(() => props.player.nextTrack())
                } else {
                    props.player.nextTrack()
                }
            }}>
                <FaForward />
            </button>
        </div>
    )
}

export default Controls
