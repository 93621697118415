const fragmentShader = `

/* void main() {
  gl_FragColor = vec4(30.0/256.0, 215.0/256.0, 96.0/256.0, 1.0);
} */

uniform float u_intensity;
uniform float u_time;

varying vec2 vUv;
varying float vDisplacement;

void main() {
  float distort = 2.0 * vDisplacement * u_intensity;

  vec3 color = vec3(abs(vUv.y - 0.5) * 0.75  * (1.0 - distort), 215.0/256.0, abs(vUv.x - 0.5) * 1.75  * (1.0 - distort));
  
  gl_FragColor = vec4(color, 1.0);
}

`

export default fragmentShader
