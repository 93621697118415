import React from 'react';
import ReactDOM from 'react-dom';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import Home from './reworked/home'
import AuthCallback from './routes/auth/callback'
import AdBlockDetector from './components/adBlockDetector';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/auth/callback",
    element: <AuthCallback />,
  },
]);

const App = () => {
  return (
    <div>
      <AdBlockDetector />
      <RouterProvider router={router} />
    </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
