import { useSearchParams, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

/* We use this to get the authentication code from the server. The server redirects here with a 'code' getParam. */

export default function AuthCallback() {
    const history = useNavigate()
    const [params] = useSearchParams()
    var CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID
    var CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET

    useEffect(() => {
        localStorage.setItem('code', params.get('code'))
        history('/')

        /* const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + (Buffer.from(CLIENT_ID + ':' + CLIENT_SECRET).toString('base64')),
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                code: localStorage.getItem('code'),
                redirect_uri: window.origin + window.location.pathname,
                grant_type: 'authorization_code'
            })
        }

        fetch('https://accounts.spotify.com/api/token', requestOptions)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(data => {
                if (data) {
                    localStorage.setItem('access_token', data.access_token)
                    localStorage.setItem('refresh_token', data.refresh_token)
                    localStorage.setItem('expires_in', data.expires_in)
                    localStorage.setItem('received_at', DateTime.now().toSeconds())

                    history('/')
                }
            })
            .catch((error) => {
                console.error(error);
            }); */
    }, [params, CLIENT_ID, CLIENT_SECRET, history]);

    return (<div>Waiting for Spotify...</div>)
}
