import { useEffect, useState } from "react"
import BlobTest from "./blobTest";

export default function Visualizer(props) {
    const [vol, setVol] = useState(null)
    const [beatIntensity, setBeatIntensity] = useState(null)
    const [my_timbre, setTimbre] = useState([])

    /* const [test, setTest] = useState([]) */
    const [idx, setIdx] = useState(0) // set to -1, dont start analysis until 0+
    const [idx1, setIdx1] = useState(0)

    useEffect(() => {
        let i = idx;
        let i1 = idx1;

        function dBToPercentage(dB, minDB, maxDB) {
            // Ensure the dB value is within the specified range
            dB = Math.min(maxDB, Math.max(minDB, dB));

            // Calculate the percentage based on the dB value
            const percentage = ((dB - minDB) / (maxDB - minDB)) * 100;

            return percentage;
        }

        function findCurrentMaxLoudness(segments, currentTime) {
            if (segments === null || segments[idx] === null)
                return null

            // Check if the current index is valid and within the same segment.
            if (idx >= 0) {
                const currentSegment = segments[idx];
                const segmentEnd = currentSegment.start + currentSegment.duration;

                if (currentTime >= currentSegment.start && currentTime <= segmentEnd) {
                    /* console.log('last') */
                    return currentSegment.loudness_max;
                }
            }

            // Check the next segment only if it's not the last segment used.
            if (idx >= 0 && idx < segments.length - 1) {
                const nextSegment = segments[idx + 1];
                if (currentTime >= nextSegment.start && currentTime <= nextSegment.start + nextSegment.duration) {
                    i += 1; // Update the current index
                    /* console.log('next') */
                    return nextSegment.loudness_max;
                }
            }

            // Binary search for the current segment.
            let left = 0;
            let right = segments.length - 1;

            while (left <= right) {
                const mid = Math.floor((left + right) / 2);
                const segment = segments[mid];

                if (currentTime >= segment.start && currentTime <= segment.start + segment.duration) {
                    i = mid; // Update the current index
                    /* console.log('search') */
                    return segment.loudness_max;
                } else if (currentTime < segment.start) {
                    right = mid - 1;
                } else {
                    left = mid + 1;
                }
            }

            return null;
        }

        function findCurrentBeat(currentTime) {
            if (!props.beats)
                return null

            if (idx1 >= 0) {
                const currentBeat = props.beats[idx1];
                const beatEnd = currentBeat.start + currentBeat.duration;

                if (currentTime >= currentBeat.start && currentTime <= beatEnd) {
                    /* console.log('last') */
                    return currentBeat;
                }
            }

            if (idx1 >= 0 && idx1 < props.beats.length - 1) {
                const nextBeat = props.beats[idx1 + 1];
                if (currentTime >= nextBeat.start && currentTime <= nextBeat.start + nextBeat.duration) {
                    i1 += 1; // Update the current index
                    /* console.log('next') */
                    return nextBeat;
                }
            }

            let left = 0;
            let right = props.beats.length - 1;

            while (left <= right) {
                const mid = Math.floor((left + right) / 2);
                const segment = props.beats[mid];

                if (currentTime >= segment.start && currentTime <= segment.start + segment.duration) {
                    i1 = mid; // Update the current index
                    /* console.log('found') */
                    return segment;
                } else if (currentTime < segment.start) {
                    right = mid - 1;
                } else {
                    left = mid + 1;
                }
            }
        }

        if (props.segments && props.meta) {
            const pos = props.position / 1000
            const max = findCurrentMaxLoudness(props.segments, pos)
            let perc = dBToPercentage(max, props.meta.max - 20, props.meta.max)
            setVol(Math.min(Math.max(0, perc), 100))

            const pitches = props.segments[i].pitches
            setTimbre(pitches)

            /* setTest(props.meta.test) */
            setIdx(i)
        }

        if (props.beats && props.position) {
            const currentTime = props.position / 1000;
            const currentBeat = findCurrentBeat(currentTime);

            if (currentBeat) {
                setBeatIntensity(1 - (currentTime - currentBeat.start) / currentBeat.duration)
            }

            setIdx1(i1)
        }
    }, [props, idx, idx1])

    const p = (((1 / 12) * my_timbre[0]) + ((1 / 12) * my_timbre[1]) + ((1 / 12) * my_timbre[2]) + ((1 / 12) * my_timbre[3]) + ((1 / 12) * my_timbre[4]) + ((1 / 12) * my_timbre[5]) + ((1 / 12) * my_timbre[6]) + ((1 / 12) * my_timbre[7]) + ((1 / 12) * my_timbre[8]) + ((1 / 12) * my_timbre[9]) + ((1 / 12) * my_timbre[10]) + ((1 / 12) * my_timbre[11]))
    /* const minDuration = 4
    const maxDuration = 1
    animationDuration: `${minDuration + (maxDuration - minDuration) * vol / 100}s` */
    return (
        <>
            {/* <div style={{ background: `rgba(255,255,255,${beatIntensity})` }}>{beatIntensity}</div> */}
            <div style={{ animation: 'spin 10s linear infinite', width: '300px', display: "none" }}>
                <div style={{ transform: `scale(${(((100 * (0.4 * vol / 100 + 0.6 * p) * 50)) / 100 + 50) / 100})`, transition: 'transform 0.2s ease-in-out', width: '300px' }}>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${(0.4 * vol / 100 + 0.6 * p) * 100}%` }}></div>
                    </div>

                    <br />

                    <div className="loudness-bar">
                        <div className="loudness-fill" id="loudness-fill" style={{ width: `${vol}%` }}></div>
                    </div>

                    <br />

                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${p * vol}%` }}></div>
                    </div>

                    <br />

                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${my_timbre[0] * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${my_timbre[1] * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${my_timbre[2] * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${my_timbre[3] * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${my_timbre[4] * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${my_timbre[5] * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${my_timbre[6] * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${my_timbre[7] * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${my_timbre[8] * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${my_timbre[9] * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${my_timbre[10] * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${my_timbre[11] * vol}%` }}></div>
                    </div>
                    {/* {props.meta && test && test[0] && <>
                    <br />

                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${((test[0].data[idx] - test[0].min) / (test[0].max - test[0].min)) * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${((test[1].data[idx] - test[1].min) / (test[1].max - test[1].min)) * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${((test[2].data[idx] - test[2].min) / (test[2].max - test[2].min)) * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${((test[3].data[idx] - test[3].min) / (test[3].max - test[3].min)) * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${((test[4].data[idx] - test[4].min) / (test[4].max - test[4].min)) * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${((test[5].data[idx] - test[5].min) / (test[5].max - test[5].min)) * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${((test[6].data[idx] - test[6].min) / (test[6].max - test[6].min)) * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${((test[7].data[idx] - test[7].min) / (test[7].max - test[7].min)) * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${((test[8].data[idx] - test[8].min) / (test[8].max - test[8].min)) * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${((test[9].data[idx] - test[9].min) / (test[9].max - test[9].min)) * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${((test[10].data[idx] - test[10].min) / (test[10].max - test[10].min)) * vol}%` }}></div>
                    </div>
                    <div className="loudness-bar">
                        <div className="loudness-fill" style={{ width: `${((test[11].data[idx] - test[11].min) / (test[11].max - test[11].min)) * vol}%` }}></div>
                    </div>
                </>} */}
                </div>
            </div>

            <BlobTest beatIntensity={beatIntensity} pitches={my_timbre} volume={props.paused ? 0 : vol} intensity={props.paused ? 0 : (((100 * (0.5 * vol / 100 + 0.5 * p) * 50)) / 100 + 50) / 100} />
        </>
    )
}